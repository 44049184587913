<template>
  <b-card>
    <div id="videoWrapper" />
    <b-card-title class="text-center">
      <h3> <strong> 자동화 편집 </strong> </h3>
    </b-card-title>
    <b-row>
      <b-col cols="12">
        <b-form-group
          class="text-center p-1"
        >
          <b-form-checkbox-group
            v-model="selectedDay"
            :options="dayOptions"
            size="sm"
            buttons
            button-variant="outline-primary"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <!-- select button -->
        <div
          class="text-center"
        >
          <hr>
          <v-select
            v-model="selectedButtonType"
            :options="buttonTypeOptions"
            placeholder="장치를 선택해주세요"
            :clearable="false"
            :searchable="false"
            class="mb-1"
          />
          <div
            v-if="selectedButtonType!=[]"
            class="m-2"
          >
            <b-card              
              bg-variant="transparent"              
            >
              <b-form-checkbox-group
              v-model="selectedButton"
              :options="buttonCheckbox"
              size="lg"
              buttons      
              stacked          
              button-variant="outline-primary px-5 mb-1"
            />

            </b-card>
          </div>
        </div>

        <!-- button control -->
        <div
          v-if="selectedButtonType!=[]"
          class="text-center"
        >
        <!-- work -->
          <b-form-group
            v-if="workButtons.includes(selectedButtonType.value)"
          >
            <b-form-radio-group
              v-model="selectedCommand"
              button-variant="outline-primary"
              :options="feeder1Options"
              buttons
              class="p-1 d-flex"
            />
          </b-form-group>

          <!-- open / stop / close -->
          <b-form-group
            v-if="openStopCloseButtons.includes(selectedButtonType.value)"
          >
            <b-form-radio-group
              v-model="selectedCommand"
              button-variant="outline-primary"
              :options="buttonOptions"
              buttons
              class="p-1 d-flex"
            />
          </b-form-group>

          <!-- slider, work / stop -->
          <b-form-group
            v-if="workStopButtons.includes(selectedButtonType.value)"
          >
            <vue-slider
              v-if="selectedButtonType.value=='inverter'"
              v-model="sliderValue"
              class="p-1 m-2 text-primary"
              tooltip="always"
              :min="15"
              :interval="15"
              :max="60"
              :tooltip-formatter="`${sliderValue/15} 단`"
            />
            <b-form-radio-group
              v-model="selectedCommand"
              button-variant="outline-primary"
              :options="inverterOptions"
              buttons
              class="p-1 d-flex mt-2"
            />
          </b-form-group>
        </div>
      </b-col>

      <b-col cols="12">
        <div class="divider my-2">
          <div class="divider-text text-primary">
            Mode Setting
          </div>
        </div>
        <b-form-group
          class="text-center pt-1 pb-1"
        >
        <!-- oneButtonOption -->
        <!-- work -->
          
          <v-select
            v-model="selectedMode"
            v-if="selectedButtonType.value==='fan' || selectedButtonType.value === 'fan_trigger'"
            :options="modeTimeOptions"
            placeholder="자동화 모드를 선택해주세요"
            :clearable="false"
            :searchable="false"
          />

          <v-select
            v-model="selectedMode"
            v-else-if="selectedButtonType.value==='feeder' 
            || selectedButtonType.value==='light' 
            || selectedButtonType.value==='feeder_1button'
            || selectedButtonType.value==='curtain'
            || selectedButtonType.value==='ceiling'
            || selectedButtonType.value==='ceiling_2button'
            || selectedButtonType.value==='inverter'"
            :options="modeTimeOptions"
            placeholder="자동화 모드를 선택해주세요"
            :clearable="false"
            :searchable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>


    <!-- time setting -->
    <b-row v-if="selectedMode.value==='time'">
      <b-col cols="12">
        <b-form-group
          label="동작 시간"
          class="text-center"
        >
          <b-form-timepicker
            v-model="startTime"
            minutes-step="1"
            label-ampm="오전/오후"
            label-am="오전"
            label-pm="오후"
            label-no-time-selected="시간 선택"
            no-close-button
            required
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- sensor setting -->
    <b-row v-if="selectedMode.value==='sensor'">
      <b-col cols="12">
        <b-form-group class="text-center">
          <v-select
            v-model="selectedSensor"
            :options="sensorOptions"
            placeholder="선택해주세요"
          />
        </b-form-group>
      </b-col>
      <b-col 
        v-if="String(selectedSensor.label).indexOf('우적') === -1 && selectedSensor.label"
        cols="12">
        <b-form-group
          label="설정온도"
          class="text-center"
        >
          <b-form-input
            v-model="minValue"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- submit and reset -->
    <b-row v-if="selectedButton.length !== 0">
      <b-col>
        <b-button
          type="submit"
          variant="primary"
          class="float-right mt-1"
          block
          @click="submit"
        >
          설정
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormGroup, BButton, BFormCheckboxGroup, BCard, BCardTitle, BFormTimepicker, BFormSpinbutton, BFormInput, BFormRadioGroup,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import vSelect from 'vue-select'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { getUserData } from '@/auth/utils'

export default {
  name: 'remote-edit',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BFormGroup,
    BButton,
    BFormCheckboxGroup,
    vSelect,
    BFormTimepicker,
    BFormSpinbutton,
    BFormInput,
    BFormRadioGroup,
    VueSlider,
  },
  data() {
    return {
      buttonId: this.$route.params.buttonId,
      autoId: this.$route.params.autoId,
      index: '',
      selectedDay: [0, 1, 2, 3, 4, 5, 6],
      dayOptions: [
        { text: '월', value: 0 },
        { text: '화', value: 1 },
        { text: '수', value: 2 },
        { text: '목', value: 3 },
        { text: '금', value: 4 },
        { text: '토', value: 5 },
        { text: '일', value: 6 },
      ],
      modeOptions: [
        { label: '시간 설정', value: 'time' },
        //{ label: '주기 설정', value: 'periodic' },
        { label: '센서 설정', value: 'sensor' },
      ],
      modeTimeOptions: [
        { label: '시간 설정', value: 'time' },
      ],
      selectedMode: '',

      // time
      startTime: '00:00',
      endTime: '00:00',
      inputTime: 1,
      selectedTime: { label: '분', value: 'min' },
      timeOptions: [
        { label: '분', value: 'min' },
        { label: '시간', value: 'hour' },
        { label: '일', value: 'day' },
      ],

      // periodic
      selectedSensor: '',
      sensorOptions: [],

      // sensor
      minValue: 0,
      maxValue: 0,

      selectedCommand: 'stop',
      buttonOptions: [
        { text: '열기', value: 'open' },
        { text: '중지', value: 'stop' },
        { text: '닫기', value: 'close' },
      ],
      inverterOptions: [
        { text: '동작', value: 'work' },
        { text: '중지', value: 'stop' },
      ],
      openStopCloseButtons: [
        'curtain', 'ceiling', 'sprayer',
      ],
      workStopButtons: [
        'inverter', 'feeder', 'light', 'fan',  'ceiling_2button', //'curtain_toggle',, 'feeder_1button'
      ],
      workButtons: [
        'fan_trigger', 'feeder_1button'
      ],
      feeder1Options: [
        { text: '전원 on/off', value: 'work'},
      ],
      sliderValue: 0,

      selectedButtonType: '',
      buttonTypeOptions: [],
      buttonCheckbox: [],
      selectedButton: [],
    }
  },
  watch: {

  },
  created() {
    this.initData()
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      // fetch data
      const autoData = await store.dispatch('button/fetchButton', {id: this.buttonId})

      let setting = autoData.data.autoSettings
      let index = 0

      for(let i = 0 ; i < setting.length ; i++){
        if (setting[i]._id === this.autoId){
          index = i
        }
      }
      //console.log(autoData.data)

      // label
      let type = autoData.data.type
      let label = () => {
          if (type === 'curtain' || type === 'curtain_toggle') return '커튼'
          else if (type === 'ceiling' || type === 'ceiling_2button' || type === 'ceiling_reverse') return '천장'
          else if (type === 'feeder' || type === 'feeder_1button') return '사료급이기'
          else if (type === 'light') return '조명'
          else if (type === 'fan') return '선풍기'
          else if (type === 'car_disinfector_toggle' || type === 'car_disinfector_trigger') return '차량소독기'
          else if (type === 'sprayer' || type === 'sprayer_trigger') return '안개분무기'
          else if (type === 'inverter') return '인버터'
          else if (type === 'freeze') return '제상'
          else if (type === 'fan' || type === 'fan_trigger') return '선풍기'
      }

      // auto setting index
      this.index = index

      // day
      this.selectedDay = []
      for(let j = 0 ; j < setting[index].days.length ; j++){
        this.selectedDay.push(setting[index].days[j])
      }
      // mode
      if(type === 'curtain' || type === 'ceiling' || type === 'fan' || type === 'ceiling_2button' || type === 'fan_trigger'){
          this.selectedMode = (setting[index].mode === 'time') ? this.selectedMode = this.modeOptions[0] : this.selectedMode = this.modeOptions[1]         
      }
      else{
          this.selectedMode = this.modeTimeOptions[0]
      }

      // time
      this.startTime = (setting[index].startTime) ? setting[index].startTime : '00:00'
      this.endTime = '00:00'
      this.inputTime = 1
      this.selectedTime = { label: '분', value: 'min' }

      // button
      this.buttonTypeOptions = await store.getters['button/getButtonTypes']('').filter((obj => obj.label === label()))
      this.selectedButtonType = this.buttonTypeOptions
      this.selectedButtonType.value = type

      this.buttonCheckbox = await store.getters['button/getButtonInTypeOptions'](autoData.data.type).filter((obj => obj.value === this.buttonId))
      this.selectedButton = this.buttonId

      // sensor
      let temp = []
      this.sensorOptions = await store.getters['sensor/getSensorSelect']
      if (this.selectedButtonType.value === 'fan' || this.selectedButtonType.value === 'fan_trigger'){    
        for(let i = 0 ; i < this.sensorOptions.length ; i++){
          if(this.sensorOptions[i].label.indexOf('온도') !== -1){
            temp.push(this.sensorOptions[i])
          }
        }   
        this.sensorOptions = temp     
        temp = []
      }
      if (this.selectedButtonType.value === 'curtain' || this.selectedButtonType.value === 'ceiling' || this.selectedButtonType.value === 'ceiling_2button'){        
        for(let i = 0 ; i < this.sensorOptions.length ; i++){
          if(this.sensorOptions[i].label.indexOf('우적') !== -1){
            temp.push(this.sensorOptions[i])
          }
        }   
        this.sensorOptions = temp    
        temp = [] 
      }
      this.selectedSensor = this.sensorOptions.filter((obj => obj.value === setting[index].sensorId))
      this.selectedSensor.value = setting[index].sensorId
      this.selectedSensor.label = this.sensorOptions.filter((obj => obj.value === setting[index].sensorId))

      this.minValue = setting[index].minValue
      this.maxValue = setting[index].maxValue

      this.selectedCommand = setting[index].command
      /* this.sliderValue = 0     */  
      this.sliderValue = (this.selectedButtonType.value === 'inverter') ? setting[index].commandValue : 0
    },

    submit() {
      let settingParam = {}

      if (this.selectedMode.value === 'time') settingParam = this.submitTime()
      else if (this.selectedMode.value === 'periodic') settingParam = this.submitPeriodic()
      else if (this.selectedMode.value === 'sensor') settingParam = this.submitSensor()

      const param = {
        id: this.selectedButton,
        setting: settingParam,
        autoId: this.autoId,
        index: this.index,
      }
      // update Setting
      store.dispatch('button/updateSetting', { id: this.buttonId, queryBody: param }).then(() => {
        this.$emit('submit')
        this.$bvModal.msgBoxOk('자동화 설정이 변경되었습니다', {
          title: '자동화 설정 변경',
          centered: true,
        }).then(() => {
          this.$emit('update')
        })
      })
      .catch(err => {
        console.log(err)
      })
    },

    submitTime() {
      const param = {
        mode: this.selectedMode.value,
        days: this.selectedDay,
        command: this.selectedCommand,
        commandValue: this.sliderValue,
        startTime: this.startTime,
        endTime: this.endTime,
      }
      return param
    },

    submitPeriodic() {
      if (this.selectedTime.value === 'hour') {
        this.inputTime *= 60
      } else if (this.selectedTime.value === 'day') {
        this.inputTime *= 60 * 24
      }
      const param = {
        mode: this.selectedMode.value,
        days: this.selectedDay,
        command: this.selectedCommand,
        commandValue: this.sliderValue,
        periodic: this.inputTime,
      }
      return param
    },

    submitSensor() {
      const param = {
        mode: this.selectedMode.value,
        days: this.selectedDay.sort((a, b) => a - b),
        command: this.selectedCommand,
        commandValue: this.sliderValue,
        sensorId: this.selectedSensor.value,
        minValue: this.minValue,
        maxValue: this.maxValue,
      }
      return param
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

